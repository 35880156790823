import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  actionsLoading: false,
  listLoading: false,
  error: false,
  brands: null,
  categories: null,
  brandDetails: null,
  productDetails: null,
  products: null,
  commission: null,
  brandCommissionTierData: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ProductsSlice = createSlice({
  name: "products",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      state.products = null;
      state.brandDetails = null;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    productList: (state, action) => {
      state.listLoading = false;
      state.products = action.payload;
      state.error = null;
    },
    brandsList: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.brands = action.payload;
    },
    comList: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.commission = action.payload;
    },
    brandDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.brandDetails = action.payload;
    },
    productDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.productDetails = action.payload;
    },
    catList: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.categories = action.payload;
    },
    brandCommissionTierData: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.brandCommissionTierData = action.payload;
    },
  }
}); 
