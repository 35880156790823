import {all} from 'redux-saga/effects';
import {combineReducers} from 'redux';

import * as auth from '../../app/modules/auth';
import { DashboardSlice } from '../../app/modules/dashboard/_redux/dashboard/Slice';
import { ProductsSlice } from '../../app/modules/products/_redux/products/Slice';
import { CommunitySlice } from '../../app/modules/communityhub/_redux/community/Slice';
import { AnalyticsSlice } from '../../app/modules/analytics/_redux/analytics/Slice';
import { ContentSlice } from '../../app/modules/contenthub/_redux/content/Slice';

export const rootReducer = combineReducers({
  auth: auth.reducer,
  dashboard: DashboardSlice.reducer,
  products: ProductsSlice.reducer,
  community: CommunitySlice.reducer,
  analytics: AnalyticsSlice.reducer,
  content: ContentSlice.reducer
})

export type RootState = ReturnType<typeof rootReducer>

export function* rootSaga() {
  yield all([auth.saga()])
}
