import React from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App';
import { ConfigProvider } from 'antd';
// import reportWebVitals from './reportWebVitals';

import {PersistGate} from 'redux-persist/integration/react'
import {Provider} from 'react-redux'
import * as _redux from './setup'
import store, {persistor} from './setup/redux/Store'
import axios from 'axios'
import {App} from './app/App'
import { GoogleOAuthProvider } from '@react-oauth/google';


import 'antd/dist/reset.css';
// import 'styles/font.css'
import 'styles/index.scss';

const {PUBLIC_URL} = process.env
_redux.setupAxios(axios, store)



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  // <React.StrictMode>
    <GoogleOAuthProvider clientId="790270501250-9mi5aq4886vqo7jlo3l0g3hgbsf9f7f3.apps.googleusercontent.com">
       <Provider store={store}>
        <ConfigProvider
          theme={{
            token: {
              colorPrimary: '#370E78',
            },
          }}
        >
           <PersistGate persistor={persistor} loading={null}>
            <App basename={PUBLIC_URL} />
          </PersistGate>
      
        </ConfigProvider>
      </Provider>
    </GoogleOAuthProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

 