import axios from 'axios'
// console.log(process.env)
const URL = process.env.REACT_APP_API_URL || 'api'

export const LOGIN_URL = URL+"auth/user/login";
export const REGISTER_URL = URL+"auth/user/signup";
export const REQUEST_PASSWORD_URL = URL+"api/auth/forgot-password";
export const ME_URL = URL+"auth/user/login";


export function login(data) {
  // console.log("ddd", data)
  return axios.post(LOGIN_URL, data);
}

export function resend_confirmation(data) {
  return axios.post(`${URL}auth/resend-confirm-code`, data);
}

export function send_pin(data) {
  return axios.post(`${URL}auth/confirm-code`, data);
}

export function getPayments(data) {
  return axios.get(`${URL}api/bank/all`, data);
}

export function change_email(data) {
  return axios.put(`${URL}auth/user`, data);
}

export function editB(data, query) {
  return axios.put(`${URL}api/bank${query}`, data);
}


export function addBank(data) {
  return axios.post(`${URL}api/bank`, data);
}

export function check_email(params) {
  return axios.get(`${URL}auth/user/check_email${params}`);
}

export function reset_password(data) {
  return axios.post(`${URL}auth/user/reset-password`, data);
}


export function change_password(data) {
  return axios.put(`${URL}auth/user/password`, data);
}

export function change_pass(data) {
  return axios.post(`${URL}users/change-password`, data);
}

export function getSocials() {
  return axios.get(`${URL}api/socials/all`);
}

export function getProducts() {
  return axios.get(`${URL}api/product/selected_products?all=true`);
}

export function addSocials(data) {
  return axios.post(`${URL}api/socials`, data);
}

export function editUserData(body) {
  return axios.put(URL+'auth/user', body);
}

export function register(data) {
  return axios.post(REGISTER_URL, data);
}

export function requestPassword(email) {
  return axios.post(REQUEST_PASSWORD_URL, { email });
}

export function changePassword(body) {
  return axios.post(URL+'auth/user/change_pass', body);
}


export function create_business(body) {
  return axios.post(URL+'businesses', body);
}

export function edit_business(body) {
  return axios.put(URL+'businesses', body);
}

export function edit_pic(data) {
  return axios({
    method: "POST",
    url: URL+'auth/user/profile_pic',
    data: data,
    headers: { "Content-Type": "multipart/form-data" },
  })
}

export function refresh_token() {
  return axios.post(URL+'auth/refresh-token', {});
}

export function request_partner() {
  return axios.get(URL+'users/business');
}

export function getUserByToken() {
  return axios.get(ME_URL);
}

export function inviteNewUser(payload) {
  return axios.post(`${URL}businesses/${payload.businessId}/invite-user`, payload);
}

export function createBusinessUser(payload) {
  return axios.post(`${URL}businesses/create-user`, payload);
}

export function createBusinessCustomer(payload) {
  return axios.post(`${URL}businesses/create-customer`, payload);
}


export function get_social(body, type) {
  if (type === 'google') {
    return axios.post(URL+'auth/user/google', body);
  } else if (type === 'apple') {
    return axios.post(URL+'auth/user/apple', body);
  }
  return axios.post(URL+'auth/user/google', body);
}