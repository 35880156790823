import { createSlice } from "@reduxjs/toolkit";

const initialdatasState = {
  actionsLoading: false,
  listLoading: false,
  error: false,
  contents: null,
  contentList: null,
  contentDetails: null,
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const ContentSlice = createSlice({
  name: "content",
  initialState: initialdatasState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
      } else {
        state.actionsLoading = true;
      }
    },
    reset: (state, action) => {
      state.products = null;
      state.brandDetails = null;
    },
    disableLoad: (state, action) => {
      state.actionsLoading = false;
      state.listLoading = false;
      state.error = null;
    },
    allContents: (state, action) => {
      state.listLoading = false;
      state.contents = action.payload;
      state.error = null;
    },
    contentList: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.contentList = action.payload;
    },
    contentDetails: (state, action) => {
      state.listLoading = false;
      state.error = null;
      state.contentDetails = action.payload;
    },

  }
}); 
