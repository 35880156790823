import { Action } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "./AuthCRUD";

export interface ActionWithPayload<T> extends Action {
  payload?: T;
}

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  firstName: "[firstName] Action",
  lastName: "[lastName] Action",
  email: "[email] Action",
  password: "[password] Action",
  confirm: "[comfirm] Action",
  more: "[more] Action",
  RESET_TOKEN: "[RESET] TOKEN",
  country: "[country] Action",
  bank: "[bank] Action",
};

const initialAuthState: IAuthState = {
  user: undefined,
  accessToken: undefined,
  first_name: "",
  last_name: "",
  email: "",
  password: "",
  confirm: "",
  more: null,
  bank: null,
  country: null,
};

export interface IAuthState {
  user?: any;
  accessToken?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  password?: string;
  confirm?: string;
  more?: any;
  bank?: any;
  country?: any;
}

export const reducer = persistReducer(
  { storage, key: "v100-demo2-auth", whitelist: ["user", "accessToken"] },
  (
    state: IAuthState = initialAuthState,
    action: ActionWithPayload<IAuthState>
  ) => {
    switch (action.type) {
      case actionTypes.Login: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      case actionTypes.Register: {
        const accessToken = action.payload?.accessToken;
        return { accessToken, user: undefined };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.RESET_TOKEN: {
        // const { accessToken } = action.payload;
        const accessToken = action.payload?.accessToken;

        return { ...state, accessToken };
      }

      case actionTypes.UserRequested: {
        return { ...state, user: undefined };
      }

      case actionTypes.UserLoaded: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.SetUser: {
        const user = action.payload?.user;
        return { ...state, user };
      }

      case actionTypes.firstName: {
        const first_name = action.payload?.first_name;
        return { ...state, first_name };
      }

      case actionTypes.lastName: {
        const last_name = action.payload?.last_name;
        return { ...state, last_name };
      }

      case actionTypes.email: {
        const email = action.payload?.email;
        return { ...state, email };
      }

      case actionTypes.password: {
        const password = action.payload?.password;
        return { ...state, password };
      }

      case actionTypes.confirm: {
        const confirm = action.payload?.confirm;
        return { ...state, confirm };
      }

      case actionTypes.more: {
        const more = action.payload?.more;
        return { ...state, more };
      }

      case actionTypes.bank: {
        const bank = action.payload?.bank;
        return { ...state, bank };
      }

      case actionTypes.country: {
        const country = action.payload?.country;
        return { ...state, country };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (accessToken: string) => ({
    type: actionTypes.Login,
    payload: { accessToken },
  }),
  register: (accessToken: string) => ({
    type: actionTypes.Register,
    payload: { accessToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: () => ({
    type: actionTypes.UserRequested,
  }),
  fulfillUser: (user: any) => ({
    type: actionTypes.UserLoaded,
    payload: { user },
  }),
  setUser: (user: any) => ({ type: actionTypes.SetUser, payload: { user } }),
  set_first_name: (first_name: string) => ({
    type: actionTypes.firstName,
    payload: { first_name },
  }),
  set_last_name: (last_name: string) => ({
    type: actionTypes.lastName,
    payload: { last_name },
  }),
  set_email: (email: string) => ({
    type: actionTypes.email,
    payload: { email },
  }),
  set_password: (password: string) => ({
    type: actionTypes.password,
    payload: { password },
  }),
  set_confirm: (confirm: string) => ({
    type: actionTypes.confirm,
    payload: { confirm },
  }),
  set_more: (more: string) => ({ type: actionTypes.more, payload: { more } }),
  set_bank: (bank: string) => ({ type: actionTypes.bank, payload: { bank } }),
  set_country: (country: string) => ({
    type: actionTypes.country,
    payload: { country },
  }),
  fulfillToken: (accessToken: string) => ({
    type: actionTypes.RESET_TOKEN,
    payload: { accessToken },
  }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    // if (user.user.verification_status === 'approved') {
    //   yield put(actions.fulfillUser(user))
    // } else {
    //   window.location.href = '/pending';
    // }
    yield put(actions.fulfillUser(user));
    if (user.user.verification_status !== "approved") {
      window.location.href = "/channels";
    }
  });
}
