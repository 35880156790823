import React from "react";
import { useRouteError } from "react-router-dom";

export default function ErrorPage() {
  // const error = useRouteError();
  // alert("ffff")
  // console.error(error);
  return (
    <>
      <div className="auth-container">
        <div className="inner">
         
            <h1 style = {{
              fontSize: 20,
              textAlign: 'center',
              fontWeight: '800'
            }}>
              SERVICE UNAVAILABLE!
            </h1>
            
        </div>
      </div>
    </>
  );
}
